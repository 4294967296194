import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueSweetalert2 from "vue-sweetalert2";
import { i18n } from "vue-lang-router";
import "sweetalert2/dist/sweetalert2.min.css";
import VueCompositionAPI from "@vue/composition-api";
import ZoomOnHover from "vue-zoom-on-hover";
import VueZoomer from "vue-zoomer";
import VueExcelXlsx from "vue-excel-xlsx";
import VueSocialSharing from "vue-social-sharing";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import "leaflet/dist/leaflet.css";
import globalFunctions from "./service/global-functions";

// regis global func
Vue.prototype.$fnc = globalFunctions;

// apex js
import VueApexCharts from "vue-apexcharts";

// Sentry Setup
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
if (process.env.VUE_APP_SENTRY_ON == "true") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENVIRONMENT_NAME,
    logErrors: process.env.VUE_APP_DEV_MOD == "true",
  });
}
// Vue.directive("click-outside", {
//   bind(el, binding, vnode) {
//     el.clickOutsideEvent = event => {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener("click", el.clickOutsideEvent);
//   },
//   unbind(el) {
//     document.body.removeEventListener("click", el.clickOutsideEvent);
//   },
// });

Vue.use(VueSocialSharing);
Vue.use(VueExcelXlsx);
Vue.use(VueZoomer);
Vue.use(VueCompositionAPI);
Vue.use(VueSweetalert2);
Vue.use(ZoomOnHover);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

// apex js
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.use(VueCropper);
Vue.component("vueCropper", VueCropper);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
